import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { isNumeric, isLength } from 'validator';
import { getEbookPrice, getEbookCategory, getEbookItems, editEbookDetailAdmin, getPublisherTag, getPublisherKeyword, getEbookSerie } from '../../api/index.js'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { } from 'react-router-dom';
import accessPath from '../../api/index.js';
import Spinner from 'react-bootstrap/Spinner';

import dayjs, { Dayjs } from 'dayjs';
import { Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';

import ImageWithPlaceholder from '../../components/ImageWithPlaceholder/index.js';
import placeholderImg from '../../image/placeHolder_cover.png';

const EditEbookAdmin = () => {

    const [selectedPublishDateTime, setSelectedPublishDateTime] = useState(dayjs());

    const location = useLocation();
    const [searchParams] = useSearchParams();

    // Get parameters from state first, then fallback to URL parameters
    const ebookDetailsIDFromState = location.state?.ebookDetailsID;
    const searchKeywordFromState = location.state?.searchKeyword;
    const searchOptionFromState = location.state?.searchOption;

    const ebookDetailsID = ebookDetailsIDFromState || searchParams.get('ebookDetailsID');
    const searchKeyword = searchKeywordFromState || searchParams.get('searchKeyword');
    const searchOption = searchOptionFromState || searchParams.get('searchOption');

    // const { ebookDetailsID , searchKeyword , searchOption } = location.state || {};
    const [checkButtonLoading, setCheckButtonLoading] = useState('1');
    const [showAlertError, setShowAlertError] = useState(false);
    const [showUploadImageErrorMessage, setUploadImageErrorMessage] = useState('');
    const [showUploadPdfErrorMessage, setUploadPdfErrorMessage] = useState('');
    const dispatch = useDispatch();
    const uploadImageRef = useRef();

    const [rightToLeft, setRightToLeft] = useState('0');

    const refSynopsis = useRef();
    const refIsbn = useRef();
    const refIsbnEbook = useRef();
    const refNote = useRef();

    const [tags, setTags] = useState([]);

    const handleTagsChange = (tags) => {
        setTags(tags);
    };


    const handleChangeRightToLeft = () => {
        const newValue = rightToLeft === '1' ? '0' : '1';
        setRightToLeft(newValue);

    }
    const [keywords, setKeywords] = useState([]);

    const handleKeywords = (tags) => {
        setKeywords(tags)
    };

    const customPlaceholder = 'Add a keywords';

    const [uploadEBook, setUploadEBook] = useState('');
    const [uploadEBookName, setUploadEBookName] = useState('');
    const [selectedFileCoverImage, setSelectedFileEbook] = useState('');
    const [bookPublisherId, setBookPublisherId] = useState('');

    const handleImageChange = (event) => {
        const acceptedTypes = ["image/jpeg", "image/jpg"];
        const file = event.target.files[0];

        if (!acceptedTypes.includes(file.type)) {
            // alert("ไฟล์รูปภาพต้องเป็น .jpg, .jpeg, หรือ .png เท่านั้น");
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: "Supported file extensions .jpg .jpeg only",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            // เคลียร์ input
            uploadImageRef.current.value = null;
            return;
        }

        setSelectedFileEbook(file);
        setUploadEBook(URL.createObjectURL(file));
        setUploadEBookName(file.name);
    };

    const [selectedPdf, setSelectedPdf] = useState(null);

    const handleBackClick = () => {
        navigate('/BooksApproval', {
            replace: true,
            state: {
                searchKeyword: searchKeyword || '',
                searchOption: searchOption || ''
            }

        });
    };

    const [textareaError, setTextareaError] = useState('');
    const [CoverPriceError, setCoverPriceError] = useState('');
    const handleChange = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === 'paperCoverPrice') {
            if (!isNumeric(value)) {
                setCoverPriceError('');
            } else if (!isLength(value, { min: 1, max: 5 })) {
                setCoverPriceError('');
            } else {
                setCoverPriceError('');
            }
        }

        if (name == 'noteTeam') {
            if (!isLength(value, { min: 0, max: 300 })) {
                setTextareaError('Please enter 300 digits');
            } else {
                setTextareaError('');
            }

        }
    }

    const [selectedOnlyOver, setOnlyOver] = useState('');
    const handleChangeOnlyOver = () => {
        const newValue = selectedOnlyOver === '1' ? '0' : '1';
        setOnlyOver(newValue);
    };

    const [pricingAndSales, setPricingAndSales] = useState('');
    const handlePricingAndSales = (event) => {
        setPricingAndSales(event.target.value);

    };

    const [publishOption, setPublishOption] = useState('as_review_pass');
    const handleStatus = (event) => {
        setPublishOption(event.target.value);

    };

    //Get ราคา EbookPrice
    const [optionsgetEbookPrice, setOptionsgetEbookPrice] = useState([]);
    function showEbookPrice() {
        getEbookPrice().then(handleShowEbookPrice)
    }
    const handleShowEbookPrice = async (response) => {
        setOptionsgetEbookPrice(response.data);
    }

    //getEbookCategory
    const [optionsgetEbookCategory, setOptionsgetEbookCategory] = useState([]);
    function showEbookCategory() {
        getEbookCategory().then(handleShowEbookCategory)
    }
    const handleShowEbookCategory = async (response) => {
        setOptionsgetEbookCategory(response.data);
    }


    const [datagetEbookDetails, setgetEbookDetails] = useState([]);
    function showEbookDetails() {
        getEbookItems(ebookDetailsID).then(handleShowEbookDetails)
    }


    //getPublisherKeyword
    const [getPublisherKeywords, setgetPublisherKeyword] = useState([]);
    function showGetPublisherKeyword() {
        getPublisherKeyword().then(handleshowGetPublisherKeyword)
    }
    const handleshowGetPublisherKeyword = async (response) => {
        setgetPublisherKeyword(response.data);
    }

    //getPublisherTag
    const [getPublisherTags, setgetPublisherTag] = useState([]);
    function showGetPublisherTag() {
        getPublisherTag().then(handleshowGetPublisherTag)
    }
    const handleshowGetPublisherTag = async (response) => {
        setgetPublisherTag(response.data);
    }


    const [otherSeries, setOtherSeries] = useState('');
    const handleChangeOtherSeries = (event) => {
        setOtherSeries(event.target.value);
    }

    //getEbookSerie
    const [getEbookSeries, setgetEbookSerie] = useState([]);
    function showgetEbookSerie() {
        getEbookSerie().then(handleshowgetEbookSerie)
    }
    const handleshowgetEbookSerie = async (response) => {
        setgetEbookSerie(response.data);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (ebookDetailsID !== null && ebookDetailsID !== undefined) {
            setPublishOption('as_review_pass');
            setPricingAndSales('for_sale');
            showEbookPrice();
            showEbookCategory();
            showEbookDetails();
            setOnlyOver('0');
            showGetPublisherKeyword();
            showGetPublisherTag();
            showgetEbookSerie();
        } else {
            handleBackClick();
        }
    }, [ebookDetailsID]);

    const [typeReader, setTypeReader] = useState('both');
    const handleReader = (event) => {
        setTypeReader(event.target.value);
    };

    const handleShowEbookDetails = async (response) => {

        //ถ้ามาเป็น 9 คือราคา free จะไม่มีใน list ให้เลือก จึงต้องทำให้เป็นว่างเหมือนไม่ได้เลือกอยู่
        var ebookDetails = response.data[0];
        if (ebookDetails["ebook_price_id"] == 9) {
            ebookDetails["ebook_price_id"] = ''
        }

        const readingMode = response.data[0]['reading_mode'];
        if (readingMode == 'ltr') {
            setRightToLeft('0');

        } else {
            setRightToLeft('1');

        }

        setgetEbookDetails(ebookDetails);

        const serieId = response.data[0]['serie_id'];
        setOtherSeries(serieId);

        setOnlyOver(response.data[0]['for_age_18_plus']);

        if (response.data[0]['sale_type'] === 'for_sale') {
            setPricingAndSales('for_sale');
        } else {
            setPricingAndSales('free');
        }

        setPublishOption(response.data[0]['publish_options']);
        setTypeReader(response.data[0]['reading_orientation']);

        //
        //TODO เช็คค่าว่าง
        if (response.data[0]['cover_image_path'] == null || response.data[0]['cover_image_path'] == '') {
            setUploadEBook('');
        } else {
            let ebookCoverImagePath = accessPath + response.data[0]['cover_image_path'];
            setUploadEBook(ebookCoverImagePath);
        }

        if (response.data[0]['uploaded_pdf_path'] == null || response.data[0]['uploaded_pdf_path'] == '') {
            setSelectedPdf(null);
        } else {
            let ebookPDFPath = accessPath + response.data[0]['uploaded_pdf_path'];
            setSelectedPdf(ebookPDFPath);
        }

        if (response.data[0]['publish_date'] != null) {
            setSelectedPublishDateTime(dayjs(response.data[0]['publish_date']))
        }

        const showKeywords = response.data[0]['keywords'];
        const showTags = response.data[0]['tags'];

        const publisherId = response.data[0]['publisher_id'];
        setBookPublisherId(publisherId);

        setKeywordsFromString(showKeywords);
        setTagsFromString(showTags);

    }

    const setKeywordsFromString = (str) => {
        if (str != '' && str != null) {
            const keywordsArray = str.split(',');
            setKeywords(keywordsArray);
        }
    };

    const setTagsFromString = (str) => {
        if (str != '' && str != null) {
            const TagsArray = str.split(',');
            setTags(TagsArray);
        }
    };

    const setKeywordsItem = (keyword) => {
        if (keyword != '' && keyword != null) {
            if (!keywords.includes(keyword)) {
                setKeywords((prevKeywords) => [...prevKeywords, keyword]);
            }
        }
    };

    const setTagsItem = (Tags) => {
        if (Tags != '' && Tags != null) {
            if (!tags.includes(Tags)) {
                setTags((prevKeywords) => [...prevKeywords, Tags]);
            }
        }
    };

    const [formData, setFormData] = useState('');
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const bookTitle = formData.bookTitle || datagetEbookDetails['book_title'];
        const authorName = formData.authorName || datagetEbookDetails['book_author'];
        
        const synopsis = refSynopsis.current.value;
        const bookType = formData.bookType || datagetEbookDetails['book_type'];

        const isbn = refIsbn.current.value;
        const isbnEbook = refIsbnEbook.current.value;

        const noteTeam = refNote.current.value;

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            setCheckButtonLoading('2');
            event.preventDefault();
            event.stopPropagation();

            editEbookDetailAdmin(
                bookPublisherId, bookTitle, authorName, selectedOnlyOver, synopsis, bookType, isbn, isbnEbook, tags, keywords,
                 noteTeam, ebookDetailsID, rightToLeft, typeReader
            ).then((response) => handleAfterUpdateEbook(response));
        } else {

        }
        setValidated(true);
    }

    async function handleAfterUpdateEbook(response) {
        handleBackClick();
    }

    const navigate = useNavigate();

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <span className='title-text'>
                                <div className='title-content-text' onClick={() => handleBackClick()}>Books Approval</div>
                                <div style={{ marginLeft: '10px', marginRight: '10px' }}>{'›'}</div>Edit E-books
                            </span>
                            <p className='context-text'>Edit your E-Book.</p>

                            <Card className='card-box card-box-top-pading'>
                                <Card.Body>
                                    <div className='content-box'>
                                        <span className='inner-title-text'>Book Details</span>
                                        <hr className='hr-title' />
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                            <Form.Group as={Row} className="mb-12" controlId="">
                                                <Form.Label column sm="4">
                                                    <div style={{ paddingBottom: '0px' }}>
                                                        <span className='my-rowlabel'>Upload E-Book Cover<span className='star' >*</span></span>
                                                    </div>
                                                </Form.Label>
                                                <Col sm="6" style={{ paddingTop: '10px' }}>
                                                    {(uploadEBook) ?
                                                        <div>
                                                            <div className="image-frame" >
                                                                <ImageWithPlaceholder
                                                                    src={uploadEBook}
                                                                    placeholder={placeholderImg}
                                                                    className='book-overview'
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>

                                                        </div>
                                                    }

                                                </Col>
                                            </Form.Group>

                                            <br></br>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Title <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control required type="text" maxLength={200} defaultValue={formData.bookTitle || datagetEbookDetails['book_title']} name="bookTitle" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Author Name <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control required type="text" defaultValue={formData.authorName || datagetEbookDetails['book_author']} name="authorName" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Category <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Select disabled required value={formData.cateGory || datagetEbookDetails['ebook_category_id']} name="cateGory" onChange={handleChange}>
                                                        <option value=''>Please Select</option>
                                                        {optionsgetEbookCategory.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name_th}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>



                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Series <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Select disabled required value={otherSeries} name="otherSeries" onChange={handleChangeOtherSeries}>
                                                        <option value='0' >Other Series</option>
                                                        {getEbookSeries.map((option) => (
                                                            <option key={option.serie_id} value={option.serie_id}>
                                                                {option.serie_name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>

                                                </Col>
                                            </Form.Group>

                                            {otherSeries === '0' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                        </Form.Label>
                                                        <Col sm="6">

                                                            <Form.Control disabled required aria-label="New Book series" maxLength={100} placeholder="New Book series" type="text" value={formData.otherSeriesName} name="otherSeriesName" onChange={handleChange} />
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            )}

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control disabled required aria-label="Order of books in this series" placeholder="Order of books in this series" type="number" min={1} defaultValue={formData.volume || datagetEbookDetails['volume']} name="volume" onChange={handleChange} />
                                                    <span className='text-alerts' >Order of books in this series</span>
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Only for People Over 18 Years Old
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Check
                                                        label="Yes"
                                                        type='checkbox'
                                                        name='onlyOver'
                                                        value='1'
                                                        onChange={handleChangeOnlyOver}
                                                        inline
                                                        checked={selectedOnlyOver === '1'}
                                                    />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Synopsis
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control
                                                        defaultValue={datagetEbookDetails['book_description']}
                                                        name="synopsis"
                                                        ref={refSynopsis}
                                                        onChange={handleChange}
                                                        as="textarea"
                                                        placeholder=""
                                                        style={{ height: '100px' }}
                                                    />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Type <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Select required value={formData.bookType || datagetEbookDetails['book_type']} name="bookType" onChange={handleChange} >
                                                        <option value=''>Please Select</option>
                                                        <option value="1" >Thai Book</option>
                                                        <option value="2" >Translated Book</option>

                                                    </Form.Select>

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control type="text" maxLength={30} ref={refIsbn} defaultValue={formData.isbn || datagetEbookDetails['isbn_number']} name="isbn" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN E-Book
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control type="text" maxLength={30} ref={refIsbnEbook} defaultValue={formData.isbnEbook || datagetEbookDetails['isbn_ebook_number']} name="isbnEbook" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">Right-to-left reading style</Form.Label>
                                                <Col sm="6">
                                                    <Form.Check
                                                        label="Yes"
                                                        type='checkbox'
                                                        name='rightToLeft'
                                                        value='1'
                                                        onChange={handleChangeRightToLeft}
                                                        inline
                                                        checked={rightToLeft === '1'}
                                                    />

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Reading Direction
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Check
                                                        label="Horizontal and Vertical Scrolling"
                                                        type='radio'
                                                        name='reader'
                                                        value='both'
                                                        onChange={handleReader}
                                                        inline
                                                        checked={typeReader === 'both'}
                                                    />

                                                    <Form.Check
                                                        label="Vertical Scrolling only"
                                                        type='radio'
                                                        name='reader'
                                                        value='vertical'
                                                        onChange={handleReader}
                                                        inline
                                                        checked={typeReader === 'vertical'}
                                                    />


                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Tags
                                                </Form.Label>
                                                <Col sm="6" style={{ border: '1px solid #ced4da', borderRadius: '6px', marginLeft: '10px', width: '48.5%', paddingTop: '10px' }}>
                                                    <TagsInput className='input-tag-custom' required value={tags || datagetEbookDetails['tags']} onChange={handleTagsChange} addOnBlur={true} addOnPaste={true} onlyUnique={true} />
                                                </Col>

                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-button'>
                                                        {getPublisherTags.map((option) => (
                                                            !tags.includes(option.tag_name) ? (
                                                                <a
                                                                    key={option.tag_name}
                                                                    style={{ marginRight: '2px', color: '#fff' }}
                                                                    onClick={() => setTagsItem(option.tag_name)}
                                                                    className="my-button"
                                                                >
                                                                    <span className='font-tags'>{option.tag_name} + </span>
                                                                </a>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Keywords
                                                </Form.Label>
                                                <Col sm="6" style={{ border: '1px solid #ced4da', borderRadius: '6px', marginLeft: '10px', width: '48.5%', paddingTop: '10px' }}>
                                                    <TagsInput className='input-keyword-custom' required value={keywords || datagetEbookDetails['keywords']} onChange={handleKeywords} placeholder={customPlaceholder} addOnBlur={true} addOnPaste={true} onlyUnique={true} inputProps={{ placeholder: customPlaceholder }} />

                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-button'>
                                                        {getPublisherKeywords.map((option) => (
                                                            (!keywords.includes(option.keyword_name)) ?
                                                                <a key={option.keyword_name} style={{ marginRight: '2px', color: '#fff' }} onClick={() => setKeywordsItem(option.keyword_name)} className="my-button-keyboard"> <span className='font-tags'>{option.keyword_name} + </span></a>
                                                                : <></>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Form.Group>


                                            <br></br>
                                            <span className='inner-title-text'>Pricing and Sales</span>
                                            <hr className='hr-title' />
                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Type Sales <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Check
                                                        disabled
                                                        label="Books for Sale"
                                                        type='radio'
                                                        name='pricingAndSales'
                                                        value='for_sale'
                                                        checked={pricingAndSales === 'for_sale'}
                                                        onChange={handlePricingAndSales}
                                                        inline
                                                    />

                                                    <Form.Check
                                                        disabled
                                                        label="Free Books"
                                                        type='radio'
                                                        name='pricingAndSales'
                                                        value='free'
                                                        checked={pricingAndSales === 'free'}
                                                        onChange={handlePricingAndSales}
                                                        inline

                                                    />
                                                </Col>
                                            </Form.Group>

                                            {pricingAndSales === 'for_sale' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Paper Cover Price  <span className='star' >*</span>
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            <Form.Control disabled required type="text" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} min="0" isInvalid={!!CoverPriceError} defaultValue={formData.paperCoverPrice || datagetEbookDetails['cover_price']} name="paperCoverPrice" onChange={handleChange}>
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                {CoverPriceError}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Book Price  <span className='star' >*</span>
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            <Form.Select disabled required value={formData.paperBookPrice || datagetEbookDetails['ebook_price_id']} name="paperBookPrice" onChange={handleChange}>
                                                                <option value=''>Please Select</option>
                                                                {optionsgetEbookPrice.map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.price}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                    </Form.Group>


                                                </div>
                                            )}

                                            <br></br>
                                            <span className='inner-title-text'>E-Book File</span>
                                            <hr className='hr-title' />

                                            <Form.Group as={Row} className="mb-3" controlId="">

                                                <Form.Label column sm="4">
                                                    <div  >
                                                        <span className='my-rowlabel'>Upload E-Book File <span className='star' >*</span> </span>
                                                    </div>
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(selectedPdf) ?
                                                        <div >
                                                            <Form.Label column sm="4">
                                                                There is a file uploaded
                                                            </Form.Label>

                                                        </div>
                                                        :
                                                        <div>

                                                        </div>
                                                    }
                                                </Col>
                                            </Form.Group>
                                            <br></br>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                Note to Reviewers
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control as="textarea" rows="3" maxLength="300" isInvalid={!!textareaError} ref={refNote} defaultValue={datagetEbookDetails['notes']} name="noteTeam" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <br></br>

                                            <center>
                                                <div>
                                                    {showAlertError && (
                                                        <Alert variant="danger" onClose={() => setShowAlertError(false)} dismissible>
                                                            <Alert.Heading>Error</Alert.Heading>
                                                            <p>
                                                                {showUploadImageErrorMessage + showUploadPdfErrorMessage}
                                                            </p>
                                                        </Alert>
                                                    )}
                                                </div>

                                                <br></br>

                                                {checkButtonLoading == '1' && (
                                                    <Button className='button-form-cancel button-main button-white' onClick={handleBackClick} variant="primary">Cancel</Button>
                                                )}

                                                {checkButtonLoading == 1 && (
                                                    <Button className='button-form-save button-main button-orange' variant="primary" type="submit">Save</Button>
                                                )}

                                                {checkButtonLoading == 2 && (
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                )}
                                            </center>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </main>
                </Col>
            </Row>
        </div >
    );
};

export default EditEbookAdmin;
