
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Icon_DotRed from '../../image/dot_red.png';
import Icon_Public from '../../image/dot_green.png';
import { Column } from 'primereact/column';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { getAutocompleteShelf, insertShelfDetails, editShelfDetails, getShelfDetails, getShelfItem, searchBookForShelf, getEbookPrice, getEbookCategory } from '../../api'
import accessPath from '../../api/index.js';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Modal, Button, InputGroup } from 'react-bootstrap';
import { showLoading, hideLoading } from '../../helper/Helper.js';
import "primereact/resources/primereact.min.css";

import ImageWithPlaceholder from '../../components/ImageWithPlaceholder/index.js';
import placeholderImg from '../../image/placeHolder_cover.png';
import { InputText } from 'primereact/inputtext';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
                }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
            {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
        }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
        }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light'
                ? 'rgba(3, 102, 214, 0.3)'
                : 'rgb(12, 45, 107)'
                }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));

const ButtonOpenAutoComplete = ({ children, icon, ...props }) => {
    return (
        <div
            className="d-flex align-items-center px-3 py-2 rounded trigger-autocomplete-button"

            {...props}
        >
            <span className="flex-grow-1">{children}</span>
            {icon}
        </div>
    );
};

const ManageShelf = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { shelfId, pagesTabId, tabName } = location.state || {};
    const [validated, setValidated] = useState(false);
    const [status, setStatus] = useState('1');
    const [displayType, setDisplayType] = useState('books');
    const useShelfTitle = useRef();
    const [valueShelfTitle, setValueShelfTitle] = useState('');
    const [dataContents, setDataContents] = useState('');
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [dynamicColumnsData, setDynamicColumnsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ebookSearchResult, setEbookSearchResult] = useState([]);
    const [selectedEbooks, setSelectedEbooks] = useState(null);
    const [formData, setFormData] = useState('');
    const cellEditCompletePending = useRef(false)

    //getEbookCategory
    const [optionsgetEbookCategory, setOptionsgetEbookCategory] = useState([]);
    function showEbookCategory() {
        getEbookCategory().then(handleShowEbookCategory)
    }
    const handleShowEbookCategory = async (response) => {
        setOptionsgetEbookCategory(response.data);
    }

    //Get ราคา EbookPrice
    const [optionsgetEbookPrice, setOptionsgetEbookPrice] = useState([]);
    function showEbookPrice() {
        getEbookPrice().then(handleShowEbookPrice)
    }
    const handleShowEbookPrice = async (response) => {
        setOptionsgetEbookPrice(response.data);
    }

    useEffect(() => {
        pageLoaded();
        showEbookPrice();
        showEbookCategory();
    }, []);

    //autocomple 

    const [autocompleteItemList, setAutocompleteItemList] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedAutoCompleteItem, setSelectedAutoCompleteItem] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState([]);
    const theme = useTheme();
    const shelfFormRef = useRef(null);

    const handleClick = (event) => {
        if (displayType == 'books') {
            setShowModal(true);
        } else {
            setPendingValue(selectedAutoCompleteItem);
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setSelectedAutoCompleteItem(pendingValue);
        //console.log(pendingValue);
        const newData = pendingValue.filter(item => item.id !== id);
        const idToArray = (newData.map(item => item.id)).join(',');

        if (idToArray != '') {
            showLoading();
            getShelfItem(displayType, idToArray).then(response => {
                setDynamicColumnsData(response.data);
                dynamicColumnsShelf();
                hideLoading();
            });
        }


        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const handleModalKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            searchBook();
        }
      };

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    const handleBackClick = (url) => {
        navigate('/' + url, {
            state: {
                shelfId: shelfId,
                pagesTabId: pagesTabId,
                tabName: tabName
            },
        });
    };

    const handleStatus = (event) => {
        setStatus(event.target.value);
    }

    const handleChangedisplayType = (event) => {
        setDisplayType(event.target.value);
        autocompleteShelf(event.target.value);
        setDynamicColumnsData([]);
        setSelectedAutoCompleteItem([]);
        setPendingValue([]);
        setDynamicColumns([]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const shelfTitle = useShelfTitle.current.value;

        let bookId = 'id'; // id ตอนเลือก Autocomplete
        if (displayType == 'books') {
            bookId = 'ebook_id'; // ถ้าเงื่อนไขเป็น books จะเปลี่ยนเป็น ebook_id
        }
        const newData = dynamicColumnsData.filter(item => item[bookId] !== id);
        const selectedIdList = newData.map(item => item[bookId]);
        let contents = selectedIdList.join(',');

        if (contents == 'undefined' || contents == '' || contents == null || contents == undefined) {
            contents = dataContents;
        }

        if (selectedIdList?.length == 0) {
            event.preventDefault();
            event.stopPropagation();

            if (displayType == 'books') {
                showAlertDialog("Error", "Please select book to display in shelf.");
            } else {
                showAlertDialog("Error", "Please select category to display in shelf.");
            }

            return;
        }

        if (form.checkValidity() === true) {
            event.preventDefault();
            event.stopPropagation();
            if (shelfId == 'undefined' || shelfId == '' || shelfId == null || shelfId == undefined) {
                insertShelfDetails(shelfTitle, displayType, contents, status, pagesTabId).then(response => {
                    navigate('/ShelfManagement', {
                        state: {
                            shelfId: shelfId,
                            pagesTabId: pagesTabId,
                            tabName: tabName
                        },

                    });

                });
            } else {
                // console.log(contents);
                editShelfDetails(shelfTitle, displayType, contents, status, shelfId).then(response => {
                    navigate('/ShelfManagement', {
                        state: {
                            shelfId: shelfId,
                            pagesTabId: pagesTabId,
                            tabName: tabName
                        },

                    });
                });
            }
        }

        setValidated(true);

    }

    const showAlertDialog = (title, message) => {

        dispatch(dialogConfirmSetData({
            title: title,
            message: message,
            closeButton: "",
            confirmButton: "OK",
            eventConfirmButton: () => null,
            eventCloseButton: () => null,
            showCloseButton: false
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    }
    function autocompleteShelf(type) {
        getAutocompleteShelf(type).then(response => {
            setAutocompleteItemList(response.data);
        });
    }

    const saveSelectedBook = () => {
        if (!dynamicColumnsData) {
            return;
        }

        if (!selectedEbooks) {
            return;
        }

        // Sort selectedEbooks
        const sortedSelectedEbooks = [...selectedEbooks].sort((a, b) => {
            // First, compare by series name
            const seriesComparison = (a.serie_name || "").localeCompare(b.serie_name || "");
            if (seriesComparison !== 0) {
                return seriesComparison;
            }

            // If series names are the same, compare by category name
            const categoryComparison = (a.category_name || "").localeCompare(b.category_name || "");
            if (categoryComparison !== 0) {
                return categoryComparison;
            }

            // If series and category names are the same, compare by volume
            const volumeA = parseInt(a.volume, 10) || 0;
            const volumeB = parseInt(b.volume, 10) || 0;
            return volumeA - volumeB;
        });

        const bookItem = new Map(dynamicColumnsData.map(item => [item.ebook_id, item]));
        const newData = [...dynamicColumnsData];

        sortedSelectedEbooks.forEach(item => {
            if (!bookItem.has(item.ebook_id)) {
                newData.push(item);
            }
        });

        for (let i = 0; i < newData.length; i++) {
            newData[i].display_order = i + 1;
        }

        setDynamicColumnsData(newData);
        setSelectedEbooks([]);
        setEbookSearchResult([]);
        setShowModal(false);
        setFormData('');
    };


    const ebookSelectionChange = (event) => {
        setSelectedEbooks(event.value);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedEbooks([]);
        setEbookSearchResult([]);
        setFormData('');
    }

    const handleChange = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const searchBook = () => {
        //รับค่า
        const bookTitle = formData.bookTitle || "";
        const category = formData.category || "";
        const paperBookPrice = formData.paperBookPrice || "";
        const paperBookPriceTo = formData.paperBookPriceTo || "";
        const searchOption = formData.searchOption || "title";
        setSelectedEbooks([]);
        showLoading()
        searchBookForShelf(bookTitle, category, paperBookPrice, paperBookPriceTo, searchOption).then(response => {
            // console.log(response.data);
            setEbookSearchResult(response.data);
            hideLoading();
        });
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <div className='td-text image-as-title'>
                <ImageWithPlaceholder
                    src={accessPath + rowData.image_path}
                    placeholder={placeholderImg}
                    className='book-overview-page-Shelf'
                />
                <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>
                    {rowData.name || '-'}
                    <span className='context-text1' style={{ margin: 0 }}>ISBN: {rowData.isbn || '-'}</span>
                </span>
            </div>
        );
    };

    function showShelfDetails() {
        // console.log("HHHH " + shelfId);
        if (shelfId != 'undefined' || shelfId != '' || shelfId != null || shelfId != undefined) {
            showLoading();
            getShelfDetails(shelfId).then(response => {
                autocompleteShelf(response.data[0]['shelf_type']);
                setDisplayType(response.data[0]['shelf_type']);
                setStatus(response.data[0]['shelf_active']);
                setValueShelfTitle(response.data[0]['shelf_title']);
                setDataContents(response.data[0]['contents_id']);
                getShelfItem(response.data[0]['shelf_type'], response.data[0]['contents_id']).then(response => {
                    setDynamicColumnsData(response.data);

                    const selectedItem = response.data.map(({ id, name, ...rest }) => ({ id, name }));
                    setSelectedAutoCompleteItem(selectedItem);

                    setPendingValue(selectedItem);
                    hideLoading();
                });
            })
        }
    }

    const onCellEditCompleteDisplay = (e , id) => {
        let objectIdKey = id;
        let normalizedArray = dynamicColumnsData;
        var moveItem = normalizedArray.filter(item => item[objectIdKey] == e.newRowData[objectIdKey])[0];
        normalizedArray.splice(e.rowIndex, 1);

        moveItem.display_order = parseInt(e.newRowData.display_order);
        var newIndex = parseInt(e.newRowData.display_order) - 1;
        normalizedArray.splice(newIndex, 0, moveItem);
        for (let i = 0; i < normalizedArray.length; i++) {
            normalizedArray[i].display_order = i + 1;
        }

        setDynamicColumnsData(normalizedArray);
        // console.log(normalizedArray);
        // reorderItem(reorderItemDisplay);

    }

    const textEditorDisplayOrder = (options) => {
        // console.log(options);
        return (
            <InputText
                type="number"
                defaultValue={options.rowData.display_order}
                // defaultValue={options?.value || }
                onChange={(e) => options.editorCallback(e.target.value)}
                //onBlur={(e) => options.editorCallback(e.target.value)} // Update on blur
                onBlur={(e) => {
                    if (e.target.value !== options.value) {
                        options.editorCallback(e.target.value);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        options.editorCallback(e.target.value); // Update on Enter key press
                    }
                }}
                style={{ width: '100%' }} // Adjust width
            />
        );
    };

    function dynamicColumnsShelf() {
  
        if (displayType == 'books') {

            setDynamicColumns(
                [
                    {
                        field: 'display_order', header: 'Order', render: (rowData, index) => (
                            <p className='td-text'>
                                {rowData.display_order}
                            </p>
                        ), editor: (options) => textEditorDisplayOrder(options),
                        onCellEditCompleteDisplay: (event) => {
                            // ป้องกันการเรียกซ้ำซ้อน
                            if (cellEditCompletePending.current) {
                                return;
                            }

                            showLoading();

                            // ตั้งค่าเป็น true เพื่อป้องกันการทำงานซ้ำ
                            cellEditCompletePending.current = true;
                        
                            // ตั้งค่า timeout เพื่อป้องกันการเรียกซ้ำจากหลาย event
                            setTimeout(() => {
                                // Logic สำหรับการบันทึกผลการแก้ไขเซลล์ที่นี่
                              //  console.log('Cell edited:', event);
                              onCellEditCompleteDisplay(event , 'ebook_id');
                                // ตั้งค่ากลับเป็น false เมื่อเสร็จสิ้น
                                cellEditCompletePending.current = false;

                                hideLoading();
                            }, 0); // ใช้ timeout สั้นๆ เพื่อให้แน่ใจว่าฟังก์ชันทำงานเพียงครั้งเดียว

                            
                        }
                    },
                    {
                        field: 'name', header: 'Book Title', render: (rowData) => (
                            <div className='td-text image-as-title'>
                                <ImageWithPlaceholder
                                    src={accessPath + rowData.image_path}
                                    placeholder={placeholderImg}
                                    className='book-overview-page-Shelf'
                                />
                                <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>
                                    {rowData.name || '-'}
                                    <span className='context-text1' style={{ margin: 0 }}>ISBN: {rowData.isbn || '-'}</span>
                                </span>
                            </div>
                        )

                    },
                    {
                        field: 'sale_units', header: 'Sales units', render: (rowData) => (
                            <p className='td-text'>
                                {rowData.sale_units}
                            </p>
                        )
                    },
                    {
                        field: 'category_name', header: 'Category', render: (rowData) => (
                            <p className='td-text'>
                                {rowData.category_name}
                            </p>
                        )
                    },
                    {
                        field: 'price', header: 'Price (฿)', render: (rowData) => (
                            <p className='td-text'>
                                {rowData.price || '-'}
                            </p>
                        )
                    },
                    {
                        field: 'Action', header: 'Action', render: (rowData) => (
                            <a onClick={() => handleDelete(rowData.ebook_id)} className='delete-link link-button'>Delete</a>
                        )
                    }
                ]
            );
        } else {

            setDynamicColumns(
                [
                    {
                        field: 'display_order', header: 'No', render: (rowData, index) => (
                            <p className='td-text'>
                                {rowData.display_order}
                            </p>
                        ), editor: (options) => textEditorDisplayOrder(options),
                        onCellEditCompleteDisplay: (event) => {
                            // ป้องกันการเรียกซ้ำซ้อน
                            if (cellEditCompletePending.current) {
                                return;
                            }

                            showLoading();
                        
                            // ตั้งค่าเป็น true เพื่อป้องกันการทำงานซ้ำ
                            cellEditCompletePending.current = true;
                        
                            // ตั้งค่า timeout เพื่อป้องกันการเรียกซ้ำจากหลาย event
                            setTimeout(() => {
                                // Logic สำหรับการบันทึกผลการแก้ไขเซลล์ที่นี่
                              //  console.log('Cell edited:', event);
                              onCellEditCompleteDisplay(event , 'id');
                                // ตั้งค่ากลับเป็น false เมื่อเสร็จสิ้น
                                cellEditCompletePending.current = false;

                                hideLoading();
                            }, 0); // ใช้ timeout สั้นๆ เพื่อให้แน่ใจว่าฟังก์ชันทำงานเพียงครั้งเดียว

                        }
                    },
                    {
                        field: 'name', header: 'Categories', render: (rowData) => (
                            <p className='td-text'>
                                {rowData.name}
                            </p>
                        )
                    },
                    {
                        field: 'status', header: 'Status', render: (rowData) => (
                            <p className='td-text'>
                                {rowData.status === 'publish' && (
                                    <React.Fragment>
                                        <img src={Icon_Public} width='12px' className='str_icon' />
                                        {'Publish'}
                                    </React.Fragment>
                                )}
                                {rowData.status === 'draft' && (
                                    <React.Fragment>
                                        <img src={Icon_DotRed} width='12px' className='str_icon' />
                                        {'Draft'}
                                    </React.Fragment>
                                )}
                            </p>
                        )
                    },
                    {
                        field: 'Action', header: 'Action', render: (rowData) => (
                            <a onClick={() => handleDelete(rowData.ebook_id)}
                                className='delete-link link-button'>
                                Delete
                            </a>
                        )
                    }
                ]
            );
        }
    }

    function onRowReorder(event) {
        // console.log(event.value);
        showLoading();
        let newData = event.value;
        for (let i = 0; i < newData.length; i++) {
            newData[i].display_order = i + 1;
        }

        setDynamicColumnsData(newData);
        hideLoading();
    }

    function handleDelete(ebook_id) {
        const newValue = selectedAutoCompleteItem.filter(item => item.ebook_id !== ebook_id);
        setSelectedAutoCompleteItem(newValue);

        const newData = dynamicColumnsData.filter(item => item.ebook_id !== ebook_id);

        for (let i = 0; i < newData.length; i++) {
            newData[i].display_order = i + 1;
        }
        setDynamicColumnsData(newData);
    }

    useEffect(() => {
        dynamicColumnsShelf();

    }, [dynamicColumnsData]);

    const dynamicColumnsDetails = dynamicColumns.map((col) => {
        return (
            <Column
                reorderable={false}
                reorderableColumns={false}
                key={col.field}
                columnKey={col.field}
                field={col.field}
                header={col.header}
                body={(rowData, options) => col.render(rowData, options.rowIndex)}
                editor={col.editor}
                onCellEditComplete={col.onCellEditCompleteDisplay}
                className={col.field === 'display_order' ? 'fixed-width-column' : ''}
            />
        );
    });


    function pageLoaded() {
        if (tabName == undefined) {
            navigate('/PagesShelf', { replace: true });
        } else {
            if (shelfId != undefined) {
                showShelfDetails();
            } else {
                // default type is books
                autocompleteShelf("books");
            }
        }

    }

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <Row>
                                <div className='col-md-12' >
                                    <Row>
                                        <div className='col-md-6'>

                                            <span className='title-text'>

                                                <div className='title-content-text' onClick={() => handleBackClick('PagesShelf')}>Pages
                                                </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp; {'›'} &nbsp;&nbsp;&nbsp;&nbsp;
                                                <div className='title-content-text' onClick={() => handleBackClick('ShelfManagement')}> {tabName}
                                                </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp; {'›'} &nbsp;&nbsp;&nbsp;&nbsp;
                                                {
                                                    shelfId == null || shelfId === '' ? (
                                                        'Add'
                                                    ) : (
                                                        'Edit'
                                                    )}
                                                &nbsp;&nbsp;
                                                {' '}Shelf
                                            </span>

                                        </div>
                                        <div className='col-md-6 col-6-div' style={{ textAlign: 'right' }}>
                                            <Row>
                                                <Col sm={5} >
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                            <br></br>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col>
                                            <main>
                                                <div className='box-table-Account2'>
                                                    <Form noValidate validated={validated} ref={shelfFormRef} onSubmit={handleSubmit}>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Shelf Title<span className='star' >*</span>
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <Form.Control required type="text" maxLength={45} ref={useShelfTitle} defaultValue={valueShelfTitle} name="referenceName" />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">Display Type<span className='star' >*</span></Form.Label>
                                                            <Col sm="5">
                                                                <Form.Select required value={displayType} name="displayType" onChange={handleChangedisplayType}>
                                                                    <option value='books' >Books</option>
                                                                    <option value='categories'>Subcategories</option>

                                                                </Form.Select>

                                                                <div className='AutocompleteInput'>
                                                                    <React.Fragment>
                                                                        <Box>
                                                                            <ButtonOpenAutoComplete onClick={handleClick} icon={<FormatListBulletedIcon />}>
                                                                                {displayType === "books" ? dynamicColumnsData?.length : pendingValue?.length} {displayType === "books" ? 'Books' : 'Subcategories'} Selected
                                                                            </ButtonOpenAutoComplete>

                                                                        </Box>
                                                                        <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                                                                            <ClickAwayListener onClickAway={handleClose}>
                                                                                <div>
                                                                                    <Box sx={{
                                                                                        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                                                                                        padding: '8px 10px',
                                                                                        fontWeight: 600,
                                                                                    }}>
                                                                                    </Box>
                                                                                    <Autocomplete
                                                                                        open
                                                                                        multiple
                                                                                        onClose={(event, reason) => {
                                                                                            if (reason === 'escape') {
                                                                                                handleClose();
                                                                                            }
                                                                                        }}
                                                                                        onChange={(event, newValue, reason) => {
                                                                                            if (
                                                                                                event.type === 'keydown' &&
                                                                                                (event.key === 'Backspace' || event.key === 'Delete') &&
                                                                                                reason === 'removeOption'
                                                                                            ) {
                                                                                                return;
                                                                                            }
                                                                                            setPendingValue(newValue);
                                                                                        }}
                                                                                        disableCloseOnSelect
                                                                                        PopperComponent={PopperComponent}
                                                                                        renderTags={() => null}
                                                                                        renderOption={(props, option, { selected }) => (
                                                                                            <li key={option.id} {...props}>
                                                                                                <Box component={DoneIcon}
                                                                                                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                                                                                                    style={{
                                                                                                        visibility: selected ? 'visible' : 'hidden',
                                                                                                    }}
                                                                                                />
                                                                                                <Box sx={{
                                                                                                    flexGrow: 1,
                                                                                                    '& span': {
                                                                                                        color:
                                                                                                            theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                                                                                    },
                                                                                                }}>
                                                                                                    {option.name}
                                                                                                </Box>
                                                                                                <Box component={CloseIcon}
                                                                                                    sx={{ opacity: 0.6, width: 18, height: 18 }}
                                                                                                    style={{
                                                                                                        visibility: selected ? 'visible' : 'hidden',
                                                                                                    }}
                                                                                                />
                                                                                            </li>
                                                                                        )}
                                                                                        options={[...autocompleteItemList].sort((a, b) => {
                                                                                            // Display the selected labels first.
                                                                                            let ai = selectedAutoCompleteItem.indexOf(a);
                                                                                            ai = ai === -1 ? selectedAutoCompleteItem?.length + autocompleteItemList.indexOf(a) : ai;
                                                                                            let bi = selectedAutoCompleteItem.indexOf(b);
                                                                                            bi = bi === -1 ? selectedAutoCompleteItem?.length + autocompleteItemList.indexOf(b) : bi;
                                                                                            return ai - bi;
                                                                                        })}
                                                                                        value={pendingValue}
                                                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                                        getOptionKey={(option) => option.id}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        renderInput={(params) => (
                                                                                            <StyledInput
                                                                                                ref={params.InputProps.ref}
                                                                                                inputProps={params.inputProps}
                                                                                                autoFocus
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            </ClickAwayListener>
                                                                        </StyledPopper>
                                                                    </React.Fragment>
                                                                </div>

                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-12" controlId="">
                                                            <Form.Label column sm="4">
                                                                <span className='my-rowlabel'>Status</span>
                                                            </Form.Label>
                                                            <Col >
                                                                <Form.Check
                                                                    label="Active"
                                                                    type='radio'
                                                                    name='Status'
                                                                    value='1'
                                                                    checked={status === '1'}
                                                                    onChange={handleStatus}
                                                                    inline

                                                                />

                                                                <Form.Check
                                                                    label="Inactive"
                                                                    type='radio'
                                                                    name='Status'
                                                                    value='0'
                                                                    checked={status === '0'}
                                                                    onChange={handleStatus}
                                                                    inline

                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                    </Form>


                                                </div>
                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>

                            <br /><br />

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col >
                                            <main>
                                                <div className='box-table-Account2'>
                                                    <span className='inner-title-text'>Selected contents</span>
                                                    <hr className='hr-title' />


                                                    <DataTable value={dynamicColumnsData} reorderableColumns={false} reorderableRows onRowReorder={onRowReorder} className="reorder-datatable" tableStyle={{ minWidth: '50rem' }} editMode="cell"  >

                                                        <Column rowReorder className='reorder-datatable-column' />
                                                        {dynamicColumnsDetails}
                                                    </DataTable>


                                                </div>
                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </main>

                    <center>
                        <Button className='button-form-cancel button-main button-white' onClick={() => handleBackClick('ShelfManagement')} variant="primary">Cancel</Button>
                        <Button className='button-form-save button-main button-orange' onClick={() => shelfFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}>Save</Button>
                    </center>
                    <br></br><br></br>
                </Col>
            </Row>


            <Modal show={showModal} onKeyDown={handleModalKeyDown} onHide={handleCloseModal} size="xl" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title>Select Books</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        <Form.Group as={Row} className="mb-2" controlId="">
                            <Form.Label column sm="1">
                                Search
                            </Form.Label>
                            <Col sm="6">

                                <InputGroup>
                                    <Form.Control style={{ width: '300px' }} className='form-control-custom' name="bookTitle" onChange={handleChange} placeholder="Search..." aria-label="Search..." />
                                    <Form.Select name='searchOption' onChange={handleChange} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} >
                                        <option value='title'>Book Title</option>
                                        <option value='series'>Book Series</option>
                                        <option value='isbn'>ISBN E-Book</option>
                                        <option value='authorName'>Author Name</option>
                                        <option value='publisher'>Publisher name</option>

                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-2" controlId="">
                            <Form.Label column sm="1">
                                Catogory
                            </Form.Label>
                            <Col sm="6">
                                <InputGroup>
                                    <Form.Select name="category" onChange={handleChange} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} >
                                        <option value=''>Please Select</option>
                                        {optionsgetEbookCategory.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name_th}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-2" controlId="">
                            <Form.Label column sm="1">
                                Price
                            </Form.Label>

                            <Col sm="6">
                                <InputGroup>
                                    <Form.Select name="paperBookPrice" onChange={handleChange}>
                                        <option value=''>Please Select</option>
                                        <option value='0'>Free</option>
                                        {optionsgetEbookPrice.map((option) => (
                                            <option key={option.price} value={option.price}>
                                                {option.price}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <InputGroup.Text>-</InputGroup.Text>
                                    <Form.Select name="paperBookPriceTo" onChange={handleChange}>
                                        <option value=''>Please Select</option>
                                        <option value='0'>Free</option>
                                        {optionsgetEbookPrice.map((option) => (
                                            <option key={option.price} value={option.price}>
                                                {option.price}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                            </Col>

                            <Button className='button-search button-main button-white' variant="primary" onClick={() => { searchBook() }} >Search</Button>

                        </Form.Group>

                        
                        <br />
                        <br />
                        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>

                            <DataTable value={ebookSearchResult}
                                selection={selectedEbooks}
                                onSelectionChange={(e) => ebookSelectionChange(e)}
                                dataKey="ebook_id"
                                className="reorder-datatable"
                                style={{ minWidth: '50rem' }}>
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column
                                    field="name"
                                    header={`${selectedEbooks?.length || 0} Item selected`}
                                    body={imageBodyTemplate}
                                ></Column>
                                <Column field="sale_units" header="Sales Units" body={(rowData) => rowData.sale_units || '-'}></Column>
                                <Column field="category_name" header="Category" body={(rowData) => rowData.category_name || '-'}></Column>
                                <Column field="price" header="Price (฿)" body={(rowData) => rowData.price || '-'}></Column>
                            </DataTable>

                        </div>
                        <br></br>
                        <center>
                            <Button className='button-form-save button-main button-orange' variant="primary" onClick={() => { saveSelectedBook() }} >Save</Button>
                        </center>

                    </Form>
                </Modal.Body>

            </Modal>
        </div>
    );
};

export default ManageShelf;
