import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import logoDownload from '../../image/download-icon-report.png'
import logoBook from '../../image/book-icon-report.png'
import { Chart as GoogleChart } from 'react-google-charts';
import { enUS } from 'date-fns/locale';
import DateRangePicker from 'rsuite/DateRangePicker';
import { subDays, subMonths, startOfMonth, endOfMonth, format, subYears } from 'date-fns';
import { convertToNumberWith2Decimal, limitPageEbookDetails, getChartDataTableJSON, getReportData, getAvailableReportDate, getAccumulatedSales, getCurrencyDisplay } from '../../api'
import accessPath from '../../api/index.js';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import 'rsuite/dist/rsuite.min.css';
import Papa from 'papaparse';
import placeholderImg from '../../image/placeHolder_cover.png'; 
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder/index.js';

const today = new Date();
const maxSelectableDate = subDays(today, 3);
const minSelectableDate = subYears(today, 2);
let defaultDateRange = [subDays(maxSelectableDate, 30), maxSelectableDate]

const predefinedRanges = [
    {
        label: 'Last 7 days',
        value: [subDays(maxSelectableDate, 7), maxSelectableDate],
        placement: 'left',
    },
    {
        label: 'Last 30 days',
        value: [subDays(maxSelectableDate, 30), maxSelectableDate],
        placement: 'left',
    },
    {
        label: 'Last 365 days',
        value: [subDays(maxSelectableDate, 365), maxSelectableDate],
        placement: 'left',
    },
    {
        label: 'This month',
        value: [startOfMonth(maxSelectableDate), maxSelectableDate],
        placement: 'left',
    },
    {
        label: 'Last month',
        value: [startOfMonth(subMonths(maxSelectableDate, 1)), endOfMonth(subMonths(maxSelectableDate, 1))],
        placement: 'left',
    },
];




const Report = () => {
    const [totalPaginCount, setTotalPaginCount] = useState(1);
    
    const [drawChartType, setDrawChartType] = useState('AreaChart'); // input at google chart option
    const [chartData, setChartData] = useState([]);
    const [accumulateSales, setAccumulateSales] = useState('');
    const [accumulateUnits, setAccumulateUnits] = useState('');
    const [totalSalesValue, setTotalSalesValue] = useState('');
    const [totalIncomeValue, setTotalIncomeValue] = useState('');
    const [totalUnitSales, setTotalUnitSales] = useState('');
    const [selectedChartType, setSelectedChartType] = useState('area'); // value to process in draw chart flow
    const [selectedViewBy, setselectedViewBy] = useState('overview'); // overview, units, sales
    const [selectedPlatform, setSelectedPlatform] = useState('all'); // all, ios, android
    const [selectedPeriodType, setSelectedPeriodType] = useState('daily'); // daily, weekly, monthly
    const [selectedSaleType, setSelectedSaleType] = useState('all'); // all, paid, free
    const [datePeriod, setDatePeriod] = useState(defaultDateRange);
    const [chartOptions, setChartOptions] = useState(null);

    const [allReportEbookByDate, setAllReportEbookByDate] = useState([]);
    const [allReportByInappItem, setAllReportByInappItem] = useState([]);
    const [allEbookDetails, setAllEbookDetails] = useState([]);

    const [platformReportEbookByDate, setPlatformReportEbookByDate] = useState([]);
    const [platformReportByInappItem, setPlatformReportByInappItem] = useState([]);
    const [platformEbookDetails, setPlatformEbookDetails] = useState([]);
    const [showNoData, setShowNoData] = useState(true);
    const noDataContentStyle = {
        display: showNoData ? 'flex' : 'none',
    };

    const { allowedRange } = DateRangePicker;

    const handleDateRangeChange = (newDateRange) => {
        setCurrentPage(1);
        var formattedDates;
        if (newDateRange && Array.isArray(newDateRange)) {
            formattedDates = newDateRange.map(date => format(new Date(date), 'yyyy-MM-dd'));

        } else {
            console.error('Invalid or missing date range');
        }

        // console.log(formattedDates);
        handleGetReport(formattedDates, selectedPlatform, selectedPeriodType, selectedChartType, selectedSaleType);
        setDatePeriod(newDateRange);
    };


    const handleSelectedPlatform = (event) => {
        setCurrentPage(1);
        let selectedValue = event.target.value;
        setSelectedPlatform(selectedValue);
        // let datePeriodString = getPeriodDateString();
        // console.log(allEbookDetails);
        if(allEbookDetails[selectedValue] !== undefined){
            setPlatformEbookDetails(allEbookDetails[selectedValue]);
            setPlatformReportByInappItem(allReportByInappItem[selectedValue]);
            setPlatformReportEbookByDate(allReportEbookByDate[selectedValue]);
    
            let selectedBook = getSelectedBooks(allEbookDetails[selectedValue]);
            
            redrawChart(selectedBook, allEbookDetails[selectedValue], allReportEbookByDate[selectedValue], selectedPeriodType, selectedViewBy, selectedChartType);
            calculateTotalValue(allEbookDetails[selectedValue])
            setShowNoData(false)
        }else{
            let datePeriodString = getPeriodDateString();
            handleGetReport(datePeriodString, selectedValue, selectedPeriodType, selectedChartType,selectedSaleType);
        }
        
    };

    const handleSelectedSaleType = (event) => {
        setCurrentPage(1);
        let selectedValue = event.target.value;
        setSelectedSaleType(selectedValue);
        let datePeriodString = getPeriodDateString();
        handleGetReport(datePeriodString, selectedPlatform, selectedPeriodType, selectedChartType,selectedValue);
    };

    const handlePeriodTypeChange = (selectedPeriod) => {
        setSelectedPeriodType(selectedPeriod)
        // const currentDate = moment();

        let dateString = getPeriodDateString();
        let maxDateString = format(maxSelectableDate, 'yyyy-MM-dd', { locale: enUS });

        getAvailableReportDate(maxDateString, dateString[0], dateString[1], selectedPeriod).then(response => {
            // console.log(response.data);
            const startDate = new Date(response.data['response_data']['startDate']);
            const endDate = new Date(response.data['response_data']['endDate']);
            const formattedStartDate = format(startDate, 'yyyy-MM-dd', { locale: enUS });
            const formattedEndDate = format(endDate, 'yyyy-MM-dd', { locale: enUS });

            // รับวันที่เป็น date object
            setDatePeriod([startDate, endDate]);
            // รับ วันที่เป็น string format 'yyyy-MM-dd'
            handleGetReport([formattedStartDate, formattedEndDate], selectedPlatform, selectedPeriod, selectedChartType,selectedSaleType);

        });

    };

    const handleSelectChartType = (newChartType) => {

        switch (newChartType) {
            case "area":
                setDrawChartType("AreaChart");
                break;
            case "line":
                setDrawChartType("LineChart");
                break;
            case "column":
                setDrawChartType("ColumnChart");
                break;
        }

        setSelectedChartType(newChartType);
        let datePeriodString = getPeriodDateString();
        handleGetReport(datePeriodString, selectedPlatform, selectedPeriodType, newChartType,selectedSaleType);

    };

    function getPeriodDateDisplayString() {
        const [startDate, endDate] = datePeriod;
        const formattedStartDate = format(startDate, 'dd/MM/yyyy', { locale: enUS });
        const formattedEndDate = format(endDate, 'dd/MM/yyyy', { locale: enUS });

        return [formattedStartDate, formattedEndDate];
    }

    function getPeriodDateString() {
        const [startDate, endDate] = datePeriod;
        const formattedStartDate = format(startDate, 'yyyy-MM-dd', { locale: enUS });
        const formattedEndDate = format(endDate, 'yyyy-MM-dd', { locale: enUS });

        return [formattedStartDate, formattedEndDate];
    }

    function getDisplayAccumulateSales() {

        const maxSelectDateString = format(maxSelectableDate, 'yyyy-MM-dd', { locale: enUS });

        getAccumulatedSales(maxSelectDateString).then(response => {
            let totalUnits = response.data["result"]["totalUnits"];
            var totalSales = response.data["result"]["totalSales"];

            totalSales = getCurrencyDisplay(convertToNumberWith2Decimal(totalSales));

            setAccumulateUnits(totalUnits);
            setAccumulateSales(totalSales);
        });
    }


    const handleGetReport = (formattedDates, platform, periodType, chartType,saleType) => {

        getReportData(formattedDates, platform, periodType,saleType, null).then(response => {
          
            if (response.data["error"] == false) {
                var result = response.data["result"];
                var ebookDetails = result["ebookDetails"];
                var reportByInappItem = result["reportByInappItem"];
                var reportByDate = result["reportByDate"];


                if (result && ebookDetails && ebookDetails['all'].length > 0) {
                    //* Do not delete : Sort packDetail ให้ตามชื่อ Pack 
                    // sort data by name
                    // Object.keys(ebookDetails).forEach( ( key ) => {
                    //     sortOn(ebookDetails[key]   , "name" ,"asc");
                    // })
                    setAllEbookDetails(ebookDetails);
                    setAllReportByInappItem(reportByInappItem);
                    setAllReportEbookByDate(reportByDate);
                    
                    setPlatformEbookDetails(ebookDetails[platform]);
                    setTotalPaginCount(ebookDetails[platform].length);
                    setPlatformReportByInappItem(reportByInappItem[platform]);
                    setPlatformReportEbookByDate(reportByDate[platform]);

                    let selectedBook = getSelectedBooks(ebookDetails[platform]);

                    redrawChart(selectedBook, ebookDetails[platform], reportByDate[platform], periodType, selectedViewBy, chartType);

                    calculateTotalValue(ebookDetails[platform])

                    setShowNoData(false);

                } else {
                    calculateTotalValue([])
                    displayNoData();
                }

            }

        });
    };

    function displayNoData(){
        setTotalIncomeValue('0')
        setTotalSalesValue('0')
        setShowNoData(true);
        clearReportGraphData();
    }

    function clearReportGraphData(){
        setAllEbookDetails([]);
        setAllReportByInappItem([]);
        setAllReportEbookByDate([]);

        setPlatformEbookDetails([]);
        setPlatformReportByInappItem([]);
        setPlatformReportEbookByDate([]);

        setTotalPaginCount(1);
    }

    // ไว้ get หนังสือที่เลือกเพื่อเอาไปวาด graph เหมือนใน crafter ใน crafter มี checkbox ให้เลือก pack ที่จะแสดงบน graph
    // ตอนนี้หนังสือยังไม่มี ui นั้น แต่ทำ function ไว้ก่อน เพื่อรองรับ
    // ตอนนี้จะ return pack ทั้งหมดไปก่อน แต่ตอนทำจริงจะต้องเลือก หนังสือ จาก ui จริง
    function getSelectedBooks(allEbooksItem) {

        let selectedItems = allEbooksItem.map(function (item) {
            return item.ebookTitle;
        });

        return selectedItems;
        // 
    }

    function redrawChart(selectedPack, arrayBookDetails, reportEbooksByDate, periodType, viewByValue, chartType) {

        var sortKey = "";

        if (viewByValue == "overview" || viewByValue == "units") {
            viewByValue = "units";
            sortKey = "units";
        } else {
            sortKey = "sales";
        }

        sortOn(arrayBookDetails, sortKey, "desc");
        var arrayForChart = new Array();

        //console.log(selectedPack);

        // เรียง array ตามอันดับที่ขายดีสุด
        arrayBookDetails.forEach(item => {
            const index = selectedPack.findIndex(element => element === item["ebookTitle"]);

            if (index !== -1) {
                arrayForChart.push(item["ebookTitle"]);
            }
        });

        getChartDataTableJSON(reportEbooksByDate, arrayForChart, periodType, viewByValue, chartType).then(response => {

            // console.log("Array for Chart : " + response["result"]);
            // setChartData(response.data["result"]);
            drawChart(response.data["result"]);
            //     calculateTotalValue();
            //     removeOverlay();

        });


    }

    // function จาก crafter file sales-report.js
    function calculateTotalValue(ebookDetailsWithPlatform) {

        //MARK เผื่อไว้ใช้กรณีมี checkbox ให้เลือกหมือน flixer
        // var selectedValue = $('.table-detail input[type=checkbox]:checked').map(function(_, el) {
        // return $(el).val();
        // }).get();
        // console.log("SelectedValue",selectedValue);
    
        // count selected item
        // var totalSelectedProceed = 0;
        // var totalSelectedSales = 0;
    
        var totalAllProceed = 0;
        var totalAllSale = 0;
        var sumUnitSales = 0;

        ebookDetailsWithPlatform.forEach(packDetails => {
            // console.log("PackDetails",packDetails);
            //MARK เผื่อไว้ใช้กรณีมี checkbox ให้เลือกหมือน flixer
            // if ($.inArray(packDetails.packName, selectedValue) >= 0) {
                // totalSelectedProceed  += convertToNumberWith2Decimal(packDetails.unitTotalProceeds);
                // totalSelectedUnit   += Number(packDetails.countUnitSales);
            // }
            
            totalAllSale  += Number(packDetails.unitTotalSales);
            totalAllProceed  += Number(packDetails.unitTotalProceeds);
            sumUnitSales += Number(packDetails.countUnitSales); //จำนวน
        });

        setTotalUnitSales(sumUnitSales);
        setTotalSalesValue(getCurrencyDisplay(convertToNumberWith2Decimal(totalAllSale)));
        setTotalIncomeValue(getCurrencyDisplay(convertToNumberWith2Decimal(totalAllProceed)));
    
    
    //DON'T DELETE
        // display selected pack value
        //var displaySelectedTotal = "$" + $.number(totalSelectedSales, 2);
        //$("#labelTotalSelectedPackSales").text(displaySelectedTotal);
        //calculateSales(totalSelectedUnit, "sales", $("#labelTotalSelectedPackSales"));
        
        //DON'T DELETE
    
        // display all pack value
        //var displayAllTotal = "$" + $.number(totalAllSale, 2);
        //$("#labelTotalAllPackSales").text(displayAllTotal);
    // calculateSales(totalAllUnit, "sales", $("#labelTotalAllPackSales"));
        
        //totalAllSales = totalAllUnit * globalItemPrice;
    
        // นับว่า มีอันที่ไม่ติ๊กมั๊ย ถ้ามีให้ไปเอาอัน checkbox all ออก
        // var check = $('#checkBoxCheckAll:checkbox:checked').length > 0;
    
        // if(check){
        // $("#rowAllUnits").hide();
        // $("#rowAllShareEarning").hide();
        // }else{
        //     $("#rowAllUnits").show();
        // $("#rowAllShareEarning").show();
        // }

        // getCurrencyDisplay(parseFloat(totalSales).toFixed(2));
    
        // calculateSalesWithPercent(totalAllSale,totalSelectedSales,"proceeds", $("#labelTotalAllPackProceeds"), $("#labelTotalSelectedPackProceeds"));
    
        // var displaySelectedUnit = $.number(totalSelectedUnit);
        // var displayTotalAllUnit = $.number(totalAllUnit);
        // var selectedPercent = Math.ceil((totalSelectedUnit / totalAllUnit) * 100);
    
        // var displayTotalValue = "(<b>" + selectedPercent +"%</b> from total of <b>" + displayTotalAllUnit + "</b> Units)";
    
    }


    function removeEbookIdFromeTitle(title) {
        const lastPipeIndex = title.lastIndexOf("||");
        if (lastPipeIndex === -1) {
            return title;
        }
        return title.substring(0, lastPipeIndex);
    }

    function getEbookIdFromTitle(title){
        const lastPipeIndex = title.lastIndexOf("||");
        if (lastPipeIndex === -1) {
            return title;
        }
        return title.substring(lastPipeIndex, title.length);
    }

    function drawChart(dataTable) {

        var dataTableObject = JSON.parse(dataTable);

        if(dataTableObject.rows !== undefined){

            var numberOfRows = dataTableObject.rows.length;
            var maxGridValue = 15;
            if (numberOfRows > maxGridValue) {
                numberOfRows = maxGridValue;
            }

            var ticksList = new Array();
            var countRow = dataTableObject.rows.length;
            for (var i = 0; i < countRow; i++) {
                var object = dataTableObject.rows[i].c[0].v;
                ticksList.push(object);
            }

            var vTitle = "";
            if (selectedViewBy == "overview" || selectedViewBy == "units") {
                vTitle = "Unit(s)";
            } else if (selectedViewBy == "sales" || selectedViewBy == "proceeds") {
                vTitle = "Baht";
            }

            var dateFormat = "MMM d, y";
            if (selectedPeriodType == "monthly") {
                dateFormat = "MMM y";
            } else if (selectedPeriodType == "yearly") {
                dateFormat = "y";
            }

            var drawChartOptions = {
                // title: 'Sales Report',
                colors: ['#E796A1', '#F3C3BD', '#B7A9C3', '#9FD6D2', '#FFDF83', '#D8D8D5'],
                chartArea: {
                    left: 100,
                    top: 14,
                    width: '75%',
                    height: '85%'
                },
                backgroundColor: '#FFFFFC',
                hAxis: {
                    //title: 'Date',
                    titleTextStyle: {
                        color: '#333',
                        fontName: 'SukhumvitSet-Text',
                    },
                    textStyle: {
                        fontName: 'SukhumvitSet-Text',
                        fontSize: 9 // or the number you want
                    },
                    gridlines: {
                        count: numberOfRows
                    },
                    ticks: ticksList,
                    format: dateFormat
                },
                vAxis: {
                    title: vTitle,
                    minValue: 0,
                    textStyle: {
                        fontName: 'SukhumvitSet-Text',
                    },
                    titleTextStyle: {
                        fontName: 'SukhumvitSet-Text',
                    },
                },
                legend: {
                    textStyle : {
                        fontName: 'SukhumvitSet-Text',
                    }
                },
                tooltip: {
                    isHtml: true
                }
            };

            setChartOptions(drawChartOptions);
            setChartData(dataTable);
        }else{
            displayNoData();
        }
    }



    // items = array to sort
    // sortBy = property to sort as --> unit, sales, proceeds, name
    // sortOption = option for sort --> asc , desc
    function sortOn(items, sortBy, sortOption) {

        // var key = "";
        // ลำดับคีย์ที่ต้องการเรียง
        var keyPriority = [];

        switch (sortBy) {
            case "units":
                keyPriority = [
                    "countUnitSales",
                    "unitTotalSales",
                    "ebookTitle"
                ];
                // key = "countUnitSales";
                break;
            case "sales":
                // key = "unitTotalSales";
                keyPriority = ["unitTotalSales"];
                break;
            case "proceeds":
                keyPriority = ["unitTotalProceeds"];
                // key = "unitTotalProceeds";
                break;
            case "range": {
                // var currentViewBy = $("#hiddenDetailViewBy").val();
                // if(currentViewBy == "units" || currentViewBy == "overview"){
                // key = "percentRange";
                // }else{
                    keyPriority = ["percentRangeProceed"];
                // key = "percentRangeProceed";
                // }
            }
                break;
            case "name":
                keyPriority = ["ebookTitle"];
                // key = "ebookTitle";
                break;
            case "previousunits":
                keyPriority = ["previouscountUnitSales"];
                // key = "previouscountUnitSales";
                break;
            case "previoussales":
                keyPriority = ["previousUnitTotalSales"];
                // key = "previousUnitTotalSales";
                break;
            case "previousproceeds":
                keyPriority = ["previousUnitTotalProceeds"];
                // key = "previousUnitTotalProceeds";
                break;
        }

        items.sort((obj1, obj2) => {
    
            // การเปรียบเทียบตามคีย์ที่เลือก
            for (const key of keyPriority) {
                let value1 = obj1[key];
                let value2 = obj2[key];
    
                if (key === "ebookTitle") {
                    // ถ้าเป็นชื่อ ให้ใช้การเปรียบเทียบสตริง
                    const comparison = value1.localeCompare(value2);
                    if (comparison !== 0) {
                        return sortOption === "asc" ? comparison : -comparison;
                    }
                } else {
                    // ถ้าเป็นตัวเลข ให้เปรียบเทียบตัวเลข
                    if (value1 !== value2) {
                        return sortOption === "asc" ? value1 - value2 : value2 - value1;
                    }
                }
            }
            // กรณีที่ทุกคีย์เท่ากันหมด
            return 0;
        });
    }
    

    useEffect(() => {
        getDisplayAccumulateSales();
        handlePeriodTypeChange('daily');
    }, []);

    //จัดหน้า limitPageEbookDetails
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * limitPageEbookDetails;
    const endIndex = startIndex + limitPageEbookDetails;
    const booksToShow = platformEbookDetails.slice(startIndex, endIndex);
 

    
    //datePeriod
  
    const exportReportEbooksCSV = (data) => {
        // แถววันที่ที่จะอยู่บนสุดของไฟล์ CSV
        let displayDateString = getPeriodDateDisplayString();

        const dateRow = `Report Date: ${displayDateString[1]} - ${displayDateString[0]}\n`;
    
        // ข้อมูลแถวหลัก
        let updatedArray = data.map(item => ({
            Book: item.ebookTitle.split("||")[0],
            ISBN: item.isbnNumber,
            "ISBN E-Book": item.isbnEbookNumber,
            Author: item.bookAuthor,
            "Sample Downloads": item.sampleCount,
            "Unit Sold": item.countUnitSales,
            "Unit Price (THB)": item.ebookPrice,
            "Gross Sales (THB)": item.unitTotalSales.toFixed(2),
            "Total Income (THB)": item.unitTotalProceeds.toFixed(2)
        }));
    
        // สร้าง CSV โดยให้แสดง header สำหรับข้อมูลหลัก
        // Create CSV with Papa Parse, specifying encoding
        const csvContent = Papa.unparse({
            fields: Object.keys(updatedArray[0]),
            data: updatedArray
        });
    
        // รวมแถววันที่กับข้อมูลหลัก โดยเพิ่มแถววันที่ให้อยู่บนสุด
        const finalCsvContent = dateRow + csvContent;

        // Add BOM (Byte Order Mark) for UTF-8 encoding
        const BOM = '\uFEFF';
        const csvWithBOM = BOM + finalCsvContent;
    
        // สร้าง blob และดาวน์โหลดไฟล์ CSV
        const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `SalesReport.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    
    
    
    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>
                        <div className='box-context'>
                            <span style={{ paddingTop: '0px', paddingBottom: '10px' }} className='title-text'> Accumulated Sale </span>


                            <div style={{ paddingBottom: '20px' }}>


                                <Row xs={1} md={2} className="g-4">
                                    <Col >
                                        <Card>
                                            <Card.Body>
                                                <center>
                                                    <div><img src={logoDownload} className="App-logo" alt="logo" width="50px" style={{ marginBottom: '10px' }} /></div>
                                                    <label style={{ fontSize: '31px', fontWeight: 'bold' }}>{accumulateUnits}</label>
                                                    <br></br>
                                                    <label style={{ fontSize: '15px', color: '#9B9DA4', fontWeight: '700' }}>Total Quantity</label>
                                                </center>
                                            </Card.Body>
                                        </Card>
                                    </Col>


                                    <Col >
                                        <Card>

                                            <Card.Body>
                                                <center>
                                                    <div><img src={logoBook} className="App-logo" alt="logo" width="50px" style={{ marginBottom: '10px' }} /></div>
                                                    <label style={{ fontSize: '31px', fontWeight: 'bold' }}>{accumulateSales }</label>
                                                    <br></br>
                                                    <label style={{ fontSize: '15px', color: '#9B9DA4', fontWeight: '700' }}>Total Book Sales</label>
                                                </center>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>



                            <span className='title-text' style={{ paddingBottom: '10px' }}> Book Analysis </span>

                            <div className='box-table'>
                                <Card >
                                    <Card.Body>

                                        <div >
                                            <div className='box-context'>

                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm="2" className='chart-option-title'>Sales Period:</Form.Label>

                                                    <DateRangePicker
                                                        className='col-md-3'
                                                        defaultValue={datePeriod}
                                                        value={datePeriod}
                                                        ranges={predefinedRanges}
                                                        character=' - '
                                                        shouldDisableDate={allowedRange(minSelectableDate, maxSelectableDate)}
                                                        onChange={handleDateRangeChange}
                                                        placeholder=""
                                                        format="dd/MM/yy"
                                                        style={{ color: 'black' }}
                                                    />

                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm="2" className='chart-option-title'>
                                                        Platform:
                                                    </Form.Label>
                                                    <Col sm="3" >
                                                        <Form.Select required name="selectPlatform" onChange={handleSelectedPlatform}>
                                                            <option value='all' >All</option>
                                                            <option value='ios' >iOS</option>
                                                            <option value='android' >Android</option>
                                                            <option value='web' >Web</option>
                                                        </Form.Select>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm="2" className='chart-option-title'>Status Type:</Form.Label>
                                                    <Col sm="3" >
                                                        <Form.Select name="selectSaleType" onChange={handleSelectedSaleType}>
                                                            <option value='all' >All</option>
                                                            <option value='paid' >Paid Books</option>
                                                            <option value='free' >Free Books</option>

                                                        </Form.Select>
                                                    </Col>
                                                </Form.Group>

                                                <Row>
                                                    <div className='col-md-12' >
                                                        <Row>
                                                            <div className='col-md-6' style={{ marginTop: '20px' }}>
                                                                <br></br>
                                                                <span className='chart-option-title'> Period:

                                                                    <a
                                                                        onClick={() => handlePeriodTypeChange('daily')}
                                                                        className={`link-button normal-link link-button-pading ${selectedPeriodType === 'daily' ? 'active' : 'inactive'}`}
                                                                        style={{ color: selectedPeriodType === 'daily' ? 'orange' : 'gray', cursor: 'pointer', textDecoration: 'none' }}
                                                                    >
                                                                        Daily
                                                                    </a>
                                                                    <font style={{ color: 'rgba(155,157,164,0.4)' }}>|</font>


                                                                    <a
                                                                        onClick={() => handlePeriodTypeChange('weekly')}
                                                                        className={`link-button normal-link link-button-pading ${selectedPeriodType === 'weekly' ? 'active' : 'inactive'}`}
                                                                        style={{ color: selectedPeriodType === 'weekly' ? 'orange' : 'gray', cursor: 'pointer', textDecoration: 'none' }}
                                                                    >
                                                                        Weekly
                                                                    </a>
                                                                    <font style={{ color: 'rgba(155,157,164,0.4)' }}>|</font>

                                                                    <a
                                                                        onClick={() => handlePeriodTypeChange('monthly')}
                                                                        className={`link-button normal-link link-button-pading ${selectedPeriodType === 'monthly' ? 'active' : 'inactive'}`}
                                                                        style={{ color: selectedPeriodType === 'monthly' ? 'orange' : 'gray', cursor: 'pointer', textDecoration: 'none' }}
                                                                    >
                                                                        Monthly
                                                                    </a>
                                                                </span>
                                                            </div>
                                                            <div className='col-md-6 col-6-div' style={{ textAlign: 'right' }}>
                                                                <span className='chart-option-title' style={{ float: 'right', marginRight: '20px' }}> Chart:

                                                                    <a onClick={() => handleSelectChartType('area')}
                                                                        className={`link-button normal-link link-button-pading ${selectedChartType === 'area' ? 'active' : 'inactive'}`}
                                                                        style={{ color: selectedChartType === 'area' ? 'orange' : 'gray', cursor: 'pointer', textDecoration: 'none' }}>
                                                                        Area
                                                                    </a>

                                                                    <font style={{ color: 'rgba(155,157,164,0.4)' }}>|</font>

                                                                    <a onClick={() => handleSelectChartType('line')}
                                                                        className={`link-button normal-link link-button-pading ${selectedChartType === 'line' ? 'active' : 'inactive'}`}
                                                                        style={{ color: selectedChartType === 'line' ? 'orange' : 'gray', cursor: 'pointer', textDecoration: 'none' }}>
                                                                        Line
                                                                    </a>
                                                                    <font style={{ color: 'rgba(155,157,164,0.4)' }}>|</font>
                                                                    <a onClick={() => handleSelectChartType('column')}
                                                                        className={`link-button normal-link link-button-pading ${selectedChartType === 'column' ? 'active' : 'inactive'}`}
                                                                        style={{ color: selectedChartType === 'column' ? 'orange' : 'gray', cursor: 'pointer', textDecoration: 'none' }}>
                                                                        Column
                                                                    </a>
                                                                </span>


                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>

                                                <div className='chart-container'>

                                                    <div className='chart-content'>

                                                        <GoogleChart
                                                            chartType={drawChartType}
                                                            width="100%"
                                                            height="500px"
                                                            data={chartData}
                                                            options={chartOptions}
                                                        />

                                                    </div>

                                                    <div className='no-data-content' style={noDataContentStyle}>
                                                        <h3>No Data Available</h3>
                                                    </div>
                                                </div>

                                                <span className='title-text' style={{ marginBottom: '10px' }}>Sales Revenue Summary</span>

                                                <div style={{ paddingBottom: '20px', marginRight: '10px' }}>
                                                    <Row xs={1} md={3} className="g-4">


                                                    <Col >
                                                            <Card>
                                                                <Card.Body>
                                                                    <center>
                                                                        <label style={{ fontSize: '31px', fontWeight: 'bold' }}>{totalUnitSales || 0 }</label>
                                                                        <br></br>
                                                                        <label style={{ fontSize: '15px', color: '#9B9DA4', fontWeight: '700' }}>Unit Sold </label>
                                                                    </center>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>

                                                        
                                                        <Col >
                                                            <Card>
                                                                <Card.Body>
                                                                    <center>
                                                                        <label style={{ fontSize: '31px', fontWeight: 'bold' }}>{totalSalesValue}</label>
                                                                        <br></br>
                                                                        <label style={{ fontSize: '15px', color: '#9B9DA4', fontWeight: '700' }}>Gross Sales</label>
                                                                    </center>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>


                                                     


                                                        <Col >
                                                            <Card>
                                                                <Card.Body>
                                                                    <center>
                                                                        <label style={{ fontSize: '31px', fontWeight: 'bold' }}>{totalIncomeValue}</label>
                                                                        <br></br>
                                                                        <label style={{ fontSize: '15px', color: '#9B9DA4', fontWeight: '700' }}>Net Revenue</label>
                                                                    </center>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>


                                                    </Row>
                                                </div>



                                                <Row>
                                                    <div className='col-md-12' >
                                                        <Row>
                                                            <div className='col-md-6' style={{ marginTop: '20px' }}>
                                                                <br></br>
                                                                <span className='title-textReport'>
                                                                Book Sales Report (Publisher)
                                                                    
                                                                </span>
                                                            </div>
                                                            <div className='col-md-6 col-6-div' style={{ textAlign: 'right' , paddingTop: '50px' }}>
                                                   

                                                                <a onClick={() => exportReportEbooksCSV(platformEbookDetails)}
                                                                    className='link-button normal-link'
                                                                    style={{ marginRight: '20px' }}>
                                                                    Export CSV
                                                                 </a>
                                                             


                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Row>

                                                <br></br>
                                                
                                                <div className='box-table'>

                                                    <Table>
                                                        <thead className="thead-text">
                                                            <tr>
                                                                <th><p>Book</p></th>
                                                                <th><p>Sample Downloads (All Time) </p></th>
                                                                <th><p>Unit Sold  </p></th>
                                                                <th><p>Unit Price (THB) </p></th>
                                                                <th><p>Gross Sales (THB) </p></th>
                                                                <th><p>Net Revenue (THB) </p></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Array.isArray(platformEbookDetails) && platformEbookDetails.length > 0 ? (
                                                                booksToShow.map((option, index) => (
                                                                    <tr  className="content-vertical-center" key={getEbookIdFromTitle(option.ebookTitle)}>
                                                                        <td>
                                                                            <div className="td-text image-as-title">
                                                                                <div style={{  marginRight: '10px' }}>
                                                                                    <ImageWithPlaceholder
                                                                                        src={accessPath + option.coverImagePath}
                                                                                        placeholder={placeholderImg}
                                                                                        className='book-overview-page'
                                                                                    />
                                                                                </div>

                                                                                <br />
                                                                                <div>

                                                                                    {removeEbookIdFromeTitle(option.ebookTitle)}
                                                                                    <div style={{ fontSize: '12px', color: '#9B9DA4', fontWeight: '700' }}>

                                                                                        ISBN: {option.isbnNumber || '-'}
                                                                                        <br />
                                                                                        ISBN E-Book: {option.isbnEbookNumber || '-'}
                                                                                        <br />
                                                                                        Author: {option.bookAuthor || '-'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td><p className="td-text" style={{ textAlign: 'center' }}>{option.sampleCount}</p></td>
                                                                        <td><p className="td-text" style={{ textAlign: 'center' }}>{option.countUnitSales}</p></td>
                                                                        <td><p className="td-text" style={{ textAlign: 'center' }}>{option.ebookPrice}</p></td>
                                                                        <td><p className="td-text" style={{ textAlign: 'center' }}>{getCurrencyDisplay(convertToNumberWith2Decimal(option.unitTotalSales))}</p></td>
                                                                        <td><p className="td-text" style={{ textAlign: 'center' }}>{getCurrencyDisplay(convertToNumberWith2Decimal(option.unitTotalProceeds))}</p></td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="7" className="text-center">
                                                                        <Form.Label style={{ color: '#9B9DA4' }} >No ebook details available</Form.Label>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                    <p className="text-danger" style={{ color: 'rgba(255, 0, 0, 0.6)', fontSize: '10px' }}>
                                                    * ข้อมูลรายงานใช้เพื่อแสดงแนวโน้มยอดขาย ซึ่งอาจมีความคลาดเคลื่อนจากข้อมูลยอดขายจริง <br></br>
                                                      หากต้องการตรวจสอบยอดขายที่แม่นยำ สามารถใช้งานเมนู Financial
                                                    </p>


                                                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <PaginationControl
                                                            totalPages={Math.ceil(platformEbookDetails.length / limitPageEbookDetails)} // จำนวนหน้าทั้งหมด
                                                            currentPage={currentPage} // หน้าปัจจุบัน
                                                            onPageChange={handlePageChange} // ฟังก์ชันเมื่อมีการเปลี่ยนหน้า
                                                        />
                                                    </div> */}

                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                   
                                                        <PaginationControl
                                                            page={currentPage}
                                                            between={4}
                                                            total={totalPaginCount}
                                                            limit={limitPageEbookDetails}
                                                            changePage={(pageNumber) => {
                                                                setCurrentPage(pageNumber);
                                                            }}
                                                            ellipsis={10}
                                                        />

                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                    </Card.Body>



                                </Card>
                            </div>
                        </div>

                    </main>

                </Col>


            </Row>
        </div>
    );
};

export default Report;
